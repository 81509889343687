import React, { useState, useEffect } from 'react';

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,video_url,caption&access_token=IGQWRPRDhGNnNObHdJVlRlemFtQmhOTC1TSENreFM0SDhNcGF3R1l4aHhKdkxJOXRtTlQyWW1aLWN1YmdUQWFtZAFZAVUS1SRHFERmhmeVdqemJaQmJnZAHBmellWUUY5WXAxTHRPVVVVMGI3TVZA5dFc1aHI4WG04NWMZD&limit=7`
        );
        if (response.ok) {
          const data = await response.json();
          setPosts(data.data.filter(post => ['IMAGE', 'VIDEO'].includes(post.media_type))); 
        } else {
          console.error('Failed to fetch Instagram posts');
        }
      } catch (error) {
        console.error('Error fetching Instagram posts:', error);
      }
    };

    fetchInstagramPosts();
  }, []); 

  return (
    <section className='instagram_wrapper'>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h6 className='text-center'>Social Snapshots</h6>
                    <h2 className='text-center mb-5'>Instagram Feed</h2>
                    <div className="posts">
                    {posts.map(post => (
                        <div key={post.id} className="post">
                            {post.media_type === 'IMAGE' ? (
                            <img src={post.media_url} alt={post.caption} />
                            ) : post.media_type === 'VIDEO' ? (
                                <video controls autoPlay loop muted>                                
                                <source src={post.media_url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            ) : null}
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default InstagramFeed;
