import React from "react";
import Home from "./home";



const App = () =>{
  return(
    <Home/>
  )
}
export default App;