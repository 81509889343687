import React, { useState } from 'react';
import logo from "../assets/image/logo.png"

const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
      };
  return (
    <section className="navbar_wrapper">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
            <a className="navbar-brand" href="/">
                <img src={logo} className="img-fluid"/>
            </a>
            <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            >
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`}>
            <ul className="navbar-nav">
                <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#aboutus">About Us</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#services">Our Services</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#trainer">Our Experts</a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="#contact">Contact Us</a>
                </li>
            </ul>
            </div>
        </div>
        </nav>
    </section>
  );
};

export default Navbar;
