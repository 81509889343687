import React from "react";
import "../assets/css/custom.css";
import Navbar from "../navbar";
import InstagramFeed from "../assets/components/Instagram";
import About_img from "../assets/image/new-about-img1.png";
import Services1 from "../assets/image/stopwatchs.png";
import Services2 from "../assets/image/mat.png";
import Services3 from "../assets/image/strongs.png";
import Services4 from "../assets/image/running.png";
import Services5 from "../assets/image/dumbbells.png";
import Services6 from "../assets/image/drink-water.png";
import logo from "../assets/image/logo.png";
import Video from "../assets/image/latest-video1.mp4";
import TrainerVideo1 from "../assets/image/trainer-video1.mp4";
import TrainerVideo2 from "../assets/image/trainer-video2.mp4";
import TrainerVideo3 from "../assets/image/trainer-video3.mp4";
import Liveimg from "../assets/image/live-1.jpeg";
import Liveimg2 from "../assets/image/live-2.jpeg";
import Whatsapp from "../assets/image/whatsapp-icon.png";

const Home = () => {
  return (
    <>
      <Navbar />
      <section className="banner_wrapper">
        <video autoPlay muted loop playsInline className="video">
          <source src={Video} type="video/mp4" />
          Sorry, your browser doesn't support videos.
        </video>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>Forge Strength, Sculpt Wellness, Elevate Mind</h1>

              <p style={{ color: "#fff" }}>
                Elevate your online presence with our React-powered websites.
                Immerse users in seamless experiences that blend innovation with
                functionality. Welcome to a realm where your vision meets the
                power of React, shaping digital excellence effortlessly.
              </p>
              <a href="#contact" className="link_btn">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="about_wrapper" id="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="aboutImg">
                <img src={About_img} alt="Img" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="about_cont">
                <h6>WE’RE</h6>
                <h2 className="mb-3">Meet Raptor Gym</h2>
                <p>
                  Welcome to Raptor Gym, where fitness is more than a routine –
                  it's a lifestyle. At Raptor Gym, we take pride in cultivating
                  a dynamic community committed to your health and well-being.
                  Our cutting-edge facilities and dedicated trainers create an
                  atmosphere that encourages personal growth and ignites a
                  passion for fitness.
                  <br /> <br />
                  Founded on the principles of dedication and excellence, Raptor
                  Gym is your partner in achieving your fitness goals. Whether
                  you're a fitness enthusiast or a beginner, our diverse range
                  of programs and a supportive community make Raptor Gym the
                  perfect place to embark on your fitness journey. Join us, and
                  let's sculpt a stronger, healthier you together at Raptor Gym!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services_wrapper" id="services">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h6>OUR SERVICE</h6>
              <h2 className="mb-3">Services We Provide</h2>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services1} className="img-fluid" />
                <h5>Functional</h5>
                <p>
                  Unleash functional strength for real-life vitality with our
                  targeted training at Raptor Gym.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services2} className="img-fluid" />
                <h5>Cardio</h5>
                <p>
                  Elevate your heart rate and embrace the rhythm of vitality
                  with our invigorating cardio sessions at Raptor Gym.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services3} className="img-fluid" />
                <h5>Musculation</h5>
                <p>
                  Unleash the power within and sculpt your strength with
                  precision through our dedicated musculation programs at Raptor
                  Gym.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services4} className="img-fluid" />
                <h5>Personal Tranings</h5>
                <p>
                  Experience personalized fitness journeys tailored to your
                  goals with our expert trainers at Raptor Gym, guiding you
                  towards a stronger, healthier version of yourself.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services5} className="img-fluid" />
                <h5>Crossfit</h5>
                <p>
                  Immerse yourself in the intensity and camaraderie of CrossFit
                  at Raptor Gym, where every WOD brings you closer to unlocking
                  your full athletic potential.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services_cont">
                <img src={Services6} className="img-fluid" />
                <h5>Sport Nutrition</h5>
                <p>
                  Elevate your performance with our sport nutrition expertise at
                  Raptor Gym, ensuring your body receives the fuel it needs to
                  conquer fitness milestones and achieve peak vitality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="liveChange_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <h6>Lives Transformed</h6>
              <h2 className="mb-3">Lives We Changed</h2>
            </div>
            <div className="col-md-6">
              <div className="imgCont">
                <img src={Liveimg} alt={Liveimg} className="img-fluid w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="imgCont">
                <img
                  src={Liveimg2}
                  alt={Liveimg2}
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 style={{ color: "#fff" }}>
                Plan Your Daily Training & Workouts
                <br />
                <strong>
                  <a
                    href="https://wa.me/6280761142?text=Hi"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    +91 6280-761142
                  </a>
                </strong>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2">
              <h6>Hear Their Stories</h6>
              <h2 className="mb-3">Client Testimonials</h2>
              <ul>
                <li>
                  <p>
                    I have been visiting this gym from past 2 months now, and I
                    am really happy with this gym, the equipments are really
                    good and well maintained, the trainers are very
                    professional, the best part about this gym is that they have
                    maintained the cleanliness and hygiene. The environment is
                    really good.
                  </p>
                  <h4>Sandipan Das</h4>
                </li>
                <li>
                  <p>
                    Great place for workout with professional trainers, great
                    ambience , hi tếch advance machines leading to great
                    results. Highly professional and dedicated trainers.
                    Friendly and peaceful Environment. Highly recommended gym
                    for your healthy lifestyle.
                  </p>
                  <h4>Anita Rattan</h4>
                </li>
                <li>
                  <p>
                    Raptor Fitness is a great gym in town. It's easy to get to,
                    has really good equipment, and it's always clean. The people
                    who work there are nice and helpful. They have lots of
                    different classes to try, too. Overall, it's a really good
                    place to go if you want to get fit and have fun doing it.
                  </p>
                  <h4>Vivek Garg</h4>
                </li>
                <li>
                  <p>
                    This gym is very good, ambience and location with proper
                    equipment and good trainer and knowledgeable. I will
                    recommend everyone for the Raptor Gym.
                  </p>
                  <h4>Chetan Trivedi</h4>
                </li>
                <li>
                  <p>
                    Best gym in kharar I love this place I highly recommended
                    this gym much visit and join
                  </p>
                  <h4>Shailesh Kumar</h4>
                </li>
                <li>
                  <p>
                    Best and biggest fitness centre in the area. Getting
                    personal attention, brand new n latest equipments.
                    Supportive staff. Must try
                  </p>
                  <h4>Gaurav Nagpal</h4>
                </li>
                <li>
                  <p>
                    I recently joined Raptor fitness Gym and it has been an
                    excellent experience!! I love this place & I would highly
                    recommend this place to anyone who wants to get in shape.
                    The trainers are very helpful, they make you feel really
                    comfortable.
                  </p>
                  <h4>Des Raj</h4>
                </li>
                <li>
                  <p>
                    One of the best gyms with unmatched equipments in whole
                    kharar region, Trainers are real fitness experts and helps
                    you incline towards your plan. Super recommendation!
                  </p>
                  <h4>Sahib Singh</h4>
                </li>
                <li>
                  <p>
                    The raptor fitness gym is very good place to workout at. The
                    trainers and the staff members are very cooperative. Even
                    gym membership fee is also not that expensive as compare to
                    other gyms.
                  </p>
                  <h4>Yatin Sachdeva</h4>
                </li>
                <li>
                  <p>
                    Great workout place.. amazing trainers.. ... Fantastic Gym.
                    Better equipment compared to any of the high profile ones in
                    the environment...
                  </p>
                  <h4>Sukhjot Singh</h4>
                </li>
                <li>
                  <p>
                    Excellent experience with raptor fitness since its opening,
                    best and advance gym equipments are available here also very
                    supportive,friendly and well experienced gym trainer are
                    there to help you in doing a effective and a safe workout❤️
                  </p>
                  <h4>Harman Dangoli</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="trainer_wrapper" id="trainer">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h6>OUR TRAINERS</h6>
              <h2 className="mb-5">Meet Our Experts</h2>
            </div>
            <div className="col-md-4">
              <div className="trainer_cont">
                <video autoPlay muted loop playsInline className="video">
                  <source src={TrainerVideo1} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>

                <div className="overlay_cont">
                  {/* <div>
                    <h4>Renne Garcia</h4>
                    <p style={{color: "#fff"}}>CrossFit Expert</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="trainer_cont">
                <video autoPlay muted loop playsInline className="video">
                  <source src={TrainerVideo3} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
                <div className="overlay_cont">
                  {/* <div>
                    <h4>Molisha Daniel</h4>
                    <p style={{color: "#fff"}}>Body Building Expert</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="trainer_cont">
                <video autoPlay muted loop playsInline className="video">
                  <source src={TrainerVideo2} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
                <div className="overlay_cont">
                  {/* <div>
                    <h4>Eleanor Grace</h4>
                    <p style={{color: "#fff"}}>Body Building Expert</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_wrapper" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.433614247314!2d76.66268497442331!3d30.762407284199934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ff159f9d0328b%3A0x4893f04fef1acad3!2sRaptor%20Fitness!5e0!3m2!1sen!2sin!4v1700481572375!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-md-5">
              <div className="contact_cont">
                <h6>CONTACT INFO</h6>
                <h2 className="mb-2">Get In Touch</h2>
                <p>
                  Do you have anything in your mind to tell us? Please don't
                  hesitate to get in touch to us via our contact form.
                </p>
                <ul>
                  <li>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <div>
                      <h3>Phone:</h3>
                      <p>+91 6280-761142</p>
                    </div>
                  </li>
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <div>
                      <h3>Address:</h3>
                      <p>
                        Sco 51,52,53,54 2ndFloor, Golden Green Market, Tehsil,
                        Sector 124, Sunny Enclave, Kharar, Punjab 140301
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <InstagramFeed />
      <footer>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <a href="">
                <img src={logo} />
              </a>
            </div>
            <div className="col-md-4 text-center">
              <div className="social">
                <a
                  href="https://www.facebook.com/profile.php?id=61552707941839"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/raptor.fitness/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://wa.me/6280761142?text=Hi" target="_blank">
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <p
                style={{ color: "#fff", margin: "6px 0 0", textAlign: "right" }}
              >
                © Copyright 2023. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
      <a className="whatspp_btn" href="https://api.whatsapp.com/send/?phone=6280761142&text=Hi&type=phone_number&app_absent=0" target="_blank">
          <img src={Whatsapp} alt="Whatsapp "/>
      </a>
    </>
  );
};

export default Home;
